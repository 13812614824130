import { Flex} from '@chakra-ui/react'

export const Grid = (props:any) => {
    return (
        <Flex
            direction={'row'}
            wrap='wrap'
            gap={6}
            justifyContent={'center'}
            {...props}
        />
    )
}