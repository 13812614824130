import { Heading, Stack, Image } from '@chakra-ui/react'
import { motion } from 'framer-motion';
import dron from '../../assets/dron.svg';

export const FilmingInProgress = () => (
    <>
        <Stack spacing={16} align='center'>
            <motion.div
                animate={{
                    rotate: [0, 2, -2, 0],
                    y: [0, -20, 0, 20, 0],
                    x: [0, 5, -5, 0]
                }}
                transition={{
                    type: 'spring',
                    duration: 4,
                    repeatType: 'reverse',
                    repeat: Infinity,
                    repeatDelay: 0
                }}
            >
                <Image width={'400px'} src={dron} />
            </motion.div>
            <Heading mt={16} textAlign={'center'} size={{ base: 'xs', lg: 'sm' }} as='h2'>{'Preparando tomas'}</Heading>
        </Stack>
    </>

)