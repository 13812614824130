import { Box, ButtonGroup, Container, IconButton, Img, Stack, Text } from '@chakra-ui/react'
import { FaInstagram, FaYoutube, FaLinkedin } from 'react-icons/fa'
import Logo from '../assets/logo-white.svg';

export const Footer = () => {
  return (
    <Box bg='bg-accent' color='on-accent'>
      <Container as='footer' role='contentinfo' py={{ base: '12', md: '16' }}>
        <Stack spacing={{ base: '4', md: '5' }}>
          <Stack justify='space-between' direction={{ base: 'column', lg: 'row' }} spacing={4} align='center'>
            <Img alt='Logo de Aero Infinity Dron' width={240} src={Logo} />
            <ButtonGroup variant='ghost-on-accent' gap={6}>
              <IconButton
                as='a'
                href='https://www.instagram.com/aero_infinity_dron'
                aria-label='Instagram'
                icon={<FaInstagram fontSize={'2rem'} />}
              />
              <IconButton
                as='a'
                href='https://www.youtube.com/@aeroinfinitydron4307'
                aria-label='YouTube'
                icon={<FaYoutube fontSize={'2rem'} />}
              />
              <IconButton
                as='a'
                href='https://www.linkedin.com/in/eduardo-ramon-montoro-2446ab12a'
                aria-label='Linkedin'
                icon={<FaLinkedin fontSize={'2rem'} />}
              />
            </ButtonGroup>
          </Stack>
          <Text fontSize='sm' color='on-accent-subtle' align={{ base: 'center', lg: 'left' }}>
            &copy; {new Date().getFullYear()} Aero Infinity Dron, todos los derechos reservados.
          </Text>
        </Stack>
      </Container>
    </Box>
  )
}


