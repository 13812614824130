var _a;
export var LOCALES = [
    'en',
    'nl',
    'fr',
    'de',
    'cs',
    'it',
    'lt',
    'pl',
    'pt',
    'es',
    'hu',
    'sk',
    'ja',
];
export var MODE = {
    PRODUCTION: 'prod',
    DEVELOPMENT: 'dev',
    SANDBOX: 'sandbox',
};
export var URLS = (_a = {},
    _a[MODE.SANDBOX] = 'https://sandbox-merchant.revolut.com/embed.js',
    _a[MODE.PRODUCTION] = 'https://merchant.revolut.com/embed.js',
    _a[MODE.DEVELOPMENT] = 'https://merchant.revolut.codes/embed.js',
    _a);
export var REVOLUT_PAY_ORDER_ID_URL_PARAMETER = '_rp_oid';
export var REVOLUT_PAY_SUCCESS_REDIRECT_URL_PARAMETER = '_rp_s';
export var REVOLUT_PAY_FAILURE_REDIRECT_URL_PARAMETER = '_rp_fr';
