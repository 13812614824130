import {
    Box,
    Heading,
    Stack,
    Text,
    Input,
    Button,
    FormControl,
    FormErrorMessage,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    ModalFooter,
    SkeletonText,
    Skeleton,
    AspectRatio,
    Image,
    ModalCloseButton,
    Spinner,
} from '@chakra-ui/react'
import { getFunctions, httpsCallable } from '@firebase/functions';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import { CTA } from '../../components/cta';
import { firebaseApp } from '../../firebase';
import { AmountSlider } from './amountSlider';
import { FilmingInProgress } from './filmingInProgress';
import RevolutCheckout from '@revolut/checkout';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc, getFirestore } from 'firebase/firestore';
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';

const functions = getFunctions(firebaseApp, 'europe-west1');
const eventCheckout = httpsCallable(functions, 'eventCheckout');

const initialAmount = 5;



const PaymentOptionsModal = ({
    onClose, token, onSuccess, onError
}: {
    onClose: () => void,
    token: string | undefined,
    onSuccess: () => void,
    onError: () => void
}) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (token) {
            setLoading(true);
            RevolutCheckout(token).then((instance) => {
                const paymentRequest = instance.paymentRequest({
                    target: document.getElementById('revolut-payment-request')!!,
                    locale: 'es',
                    onSuccess() {
                        onSuccess();
                    },
                    onError(error) {
                        console.log(`Error: ${error.message}`)
                        onError();
                    },
                })
                paymentRequest.canMakePayment().then((result) => {
                    if (result) {
                        paymentRequest.render().then(()=>{
                            setLoading(false);
                        });
                    } else {
                        onCardPayment();
                    setLoading(false);
                        paymentRequest.destroy()
                    }
                })
            })
        }
    }, [token]);
    const onCardPayment = () => {
        RevolutCheckout(token!!).then(instance => {
            onClose();
            instance.payWithPopup({
                locale: 'es',
                onSuccess: () => {
                    onSuccess();
                },
                onError: (e) => {
                    console.log('Payment ERROR -> ' + e.message);
                    onError();
                },
                onCancel: () => {
                    console.log('Payment CANCEL');
                }
            });
        });
    }
    return (
        <Modal onClose={onClose} isOpen={token !== undefined} isCentered>
            <ModalOverlay bg='blackAlpha.700' backdropFilter='auto' backdropBlur='2px' />
            <ModalContent sx={{ backgroundColor: 'white' }}>
                <ModalHeader color={'black'}>Selecciona tu forma de pago</ModalHeader>
                <ModalCloseButton color={'black'} />
                <ModalBody mt={6}>
                    <Stack>
                        {loading &&
                            <Box display={'flex'} justifyContent='center'>
                                <Spinner size='lg' color='brand.400' />
                            </Box>
                        }
                        {!loading &&
                            <Button
                                colorScheme='brand'
                                backgroundColor={'brand.600'}
                                _hover={{
                                    backgroundColor: 'brand.500',
                                    color: 'white'
                                }}
                                _active={{
                                    backgroundColor: 'brand.500',
                                    color: 'white'
                                }}
                                color='white'
                                size='lg'
                                rightIcon={<BsFillCreditCard2FrontFill fontSize={34} />}
                                onClick={onCardPayment} >
                                Pago con tarjeta
                            </Button>
                        }
                        <Box id='revolut-payment-request' />
                    </Stack>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' colorScheme='brand' onClick={onClose}>
                        CANCELAR
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}


const ErrorModal = ({
    isOpen,
    onClose
}: {
    isOpen: boolean,
    onClose: () => void,
}) => {
    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay bg='blackAlpha.700' backdropFilter='auto' backdropBlur='2px' />
            <ModalContent>
                <ModalHeader />
                <ModalCloseButton />
                <ModalBody mt={1} mb={2}>
                    <Alert
                        status={'error'}
                        variant='subtle'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        textAlign='center'
                        height='240px'
                    >
                        <AlertIcon boxSize='60px' mr={0} />
                        <AlertTitle mt={4} mb={1} fontSize='lg'>
                            {'¡Error!'}
                        </AlertTitle>
                        <AlertDescription maxWidth='sm'>
                            {'No se ha podido completar el pago. Prueba de nuevo y revisa los detalles del pago.'}
                        </AlertDescription>
                    </Alert>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' colorScheme='brand' onClick={onClose}>
                        CERRAR
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}



const SuccessModal = ({
    isOpen,
    onClose
}: {
    isOpen: boolean,
    onClose: () => void,
}) => {
    return (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay bg='blackAlpha.700' backdropFilter='auto' backdropBlur='2px' />
            <ModalContent>
                <ModalHeader />
                <ModalCloseButton />
                <ModalBody mt={1} mb={2}>
                    <Alert
                        status={'success'}
                        variant='subtle'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        textAlign='center'
                        height='240px'
                    >
                        <AlertIcon boxSize='60px' mr={0} />
                        <AlertTitle mt={4} mb={1} fontSize='lg'>
                            {'¡Pago recibido!'}
                        </AlertTitle>
                        <AlertDescription>
                            {'Gracias por tu pago. Te hemos enviado un correo electrónico con toda la información relevante.'}
                        </AlertDescription>
                    </Alert>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' colorScheme='brand' onClick={onClose}>
                        CERRAR
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export const Event = () => {
    let { id } = useParams();
    const [amount, setAmount] = React.useState(initialAmount);
    const [hasError, setHasError] = React.useState(false);
    const [orderFinished, setOrderFinished] = React.useState(false);
    const [revolutToken, setRevolutToken] = React.useState<string | undefined>();

    const [value, loading] = useDocument(
        doc(getFirestore(firebaseApp), 'events', id!!),
    );

    const checkout = (data: any) => {
        return new Promise<void>((resolve) => {
            eventCheckout({
                amount,
                id,
                email: data.email,
            }).then((result: any) => {
                setRevolutToken(result.data.token);
                resolve();
            }).catch((e) => {
                setRevolutToken(undefined);
                resolve();
                setHasError(true);
                console.error(e);
            });
        })
    }

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm();

    if (!loading && !value?.exists()) {
        return (
            <Navigate to='/' replace />
        )
    }

    return (
        <>
            <Helmet>
                <link rel='canonical' href={`https://www.aeroinfinitydron.com/events/${id}`} />
            </Helmet>
            <ErrorModal
                onClose={() => { setHasError(false) }}
                isOpen={hasError}
            />
            <SuccessModal
                onClose={() => { setOrderFinished(false) }}
                isOpen={orderFinished}
            />
            <PaymentOptionsModal
                onClose={() => { setRevolutToken(undefined) }}
                token={revolutToken}
                onSuccess={() => {
                    setRevolutToken(undefined);
                    setOrderFinished(true);
                }}
                onError={() => {
                    setRevolutToken(undefined);
                    setHasError(true);
                }}
            />
            <Stack spacing='20' px={4}>
                <Box pt='8'>
                    <Stack spacing={{ base: '8', md: '10' }} align='center' >
                        <Stack spacing={{ base: '4', md: '6' }} width={'100%'}>
                            <Stack spacing='4' textAlign='center'>
                                <Text fontWeight='semibold' color='accent' fontSize={{ base: 'sm', md: 'md' }}>
                                    {'Eventos'}
                                </Text>
                                <Heading size={{ base: 'md', md: 'lg' }} as='h1'>{value?.data()?.title}</Heading>
                                {loading &&
                                    <SkeletonText noOfLines={1} spacing='4' skeletonHeight='12' />
                                }
                            </Stack>
                            <Text fontSize={{ base: 'lg', md: 'xl' }} color='muted'>
                                {value?.data()?.description}
                            </Text>
                            {loading &&
                                <SkeletonText noOfLines={6} spacing='4' skeletonHeight='4' />
                            }
                        </Stack>
                    </Stack>
                </Box>
                {value?.data()?.wip &&
                    <FilmingInProgress />
                }
                {!value?.data()?.wip && value?.data()?.thumbnail &&
                    <Box>
                        <AspectRatio ratio={16 / 9} maxW='800px' margin={'0 auto'}>
                            <>
                                {value?.data()?.thumbnail.type?.toUpperCase() === 'YOUTUBE' &&
                                    <iframe
                                        src={`https://www.youtube.com/embed/${value?.data()?.thumbnail.content}`}
                                        frameBorder='0'
                                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                        allowFullScreen
                                        title='Embedded youtube'
                                    />
                                }
                                {value?.data()?.thumbnail.type.toUpperCase() === 'IMAGE' &&
                                    <Image src={value?.data()?.thumbnail.content} />
                                }
                            </>
                        </AspectRatio>
                    </Box>
                }
                {loading &&
                    <Stack spacing={16} align='center'>
                        <Skeleton width={400} height={300} />
                    </Stack>
                }
                <Stack spacing={8}>
                    <Text fontSize={{ base: 'lg', md: 'xl' }} color='muted'>
                        {value?.data()?.checkout_description}
                    </Text>
                    {loading &&
                        <SkeletonText noOfLines={4} spacing='4' skeletonHeight='4' />
                    }
                    {!loading &&
                        <Box>
                            <AmountSlider initialAmount={initialAmount} onAmountUpdate={setAmount} />
                            <Stack
                                as='form'
                                noValidate
                                onSubmit={handleSubmit(checkout)}
                                direction={{ base: 'column', md: 'row' }}
                                spacing='4'
                                justify='center'
                            >
                                <Stack maxW={{ md: 'lg' }} width='full'>
                                    <FormControl isRequired isInvalid={errors.email !== undefined}>
                                        <Input
                                            size='lg'
                                            type='email'
                                            placeholder='Correo electrónico'
                                            autoComplete='false'
                                            {...register('email', {
                                                required: 'Campo obligatorio.',
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: 'Introduce un correo electrónico válido.'
                                                }
                                            })}
                                        />
                                        <FormErrorMessage>
                                            {errors.email &&
                                                <>{errors.email.message}</>
                                            }
                                        </FormErrorMessage>
                                    </FormControl>

                                </Stack>
                                <Button
                                    isLoading={isSubmitting}
                                    isDisabled={revolutToken !== undefined}
                                    size='lg'
                                    colorScheme='brand'
                                    type='submit'>
                                    {'CONTINUAR'}
                                </Button>
                            </Stack>
                        </Box>
                    }
                </Stack>
                <CTA
                    title={'¿Quieres ver qué podemos hacer?'}
                    description={'Échale un vistazo a nuestro portfolio y verás qué podemos hacer. ¿Te animas a trabajar con nosotros?'}
                    link={'/#portfolio'}
                    buttonLabel={'PORTFOLIO'} />
            </Stack>
        </>
    )
}

