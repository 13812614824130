import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals, { reportToAnalytics } from './reportWebVitals';
import { App } from './app';
import { ColorModeScript } from '@chakra-ui/react';
import { theme } from './theme';
import '@fontsource/inter/variable.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </React.StrictMode>
);

reportWebVitals(reportToAnalytics);
