import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    Container,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    Textarea,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { firebaseApp } from '../../firebase';
import { useState } from 'react';

const functions = getFunctions(firebaseApp, 'europe-west1');
const contact = httpsCallable(functions, 'contact');

export const ContactForm = () => {
    const sendContact = (data: any) => {
        return new Promise<void>((resolve) => {
            contact({
                name: data.name,
                phone: data.phone,
                email: data.email,
                content: data.content
            }).then(() => {
                reset();
                setHasError(false);
            }).catch((e) => {
                setHasError(true);
                console.error(e);
            }).finally(() => {
                openModal();
                resolve();
            });
        })
    }

    const {
        handleSubmit,
        reset,
        register,
        formState: { errors, isSubmitting },
    } = useForm();

    const [error, setHasError] = useState(false);
    const { isOpen, onOpen: openModal, onClose } = useDisclosure()

    return (
        <Container
            maxW='md'
            py='8'
            px='10'
            bg='bg-surface'
            boxShadow={useColorModeValue('md', 'md-dark')}
            borderRadius='xl'
        >
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalBody>
                        <Alert
                            status={error ? 'error' : 'success'}
                            variant='subtle'
                            flexDirection='column'
                            alignItems='center'
                            justifyContent='center'
                            textAlign='center'
                            height='240px'
                        >
                            <AlertIcon boxSize='60px' mr={0} />
                            <AlertTitle mt={4} mb={1} fontSize='lg'>
                                {error ? '¡Error!' : '¡Mensaje recibido!'}
                            </AlertTitle>
                            <AlertDescription maxWidth='sm'>
                                {error ?
                                    'No hemos podido enviar el mensaje. Si el error persiste por favor ponte en contacto a través de los teléfonos que encontrarás en esta misma página.' :
                                    'Gracias por contactar con nosotros. Nos pondremos en contacto contigo lo antes posible.'
                                }
                            </AlertDescription>
                        </Alert>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant='ghost' colorScheme='brand' onClick={onClose}>
                            CERRAR
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Stack spacing='8'>
                <Stack spacing='6' align='center'>
                    <Stack spacing='3' textAlign='center'>
                        <Heading as='h1' size='xs'>{'Déjanos un mensaje'}</Heading>
                        <HStack justify='center' spacing='1'>
                            <Text color='muted'>Te responderemos lo antes posible</Text>
                        </HStack>
                    </Stack>
                </Stack>
                <Stack spacing='6'>
                    <form onSubmit={handleSubmit(sendContact)} noValidate>
                        <Stack spacing='5'>
                            <FormControl isRequired isInvalid={errors.name !== undefined}>
                                <FormLabel htmlFor='name'>Nombre</FormLabel>
                                <Input
                                    id='name'
                                    type='text'
                                    {...register('name', {
                                        required: 'Campo obligatorio.',
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.name &&
                                        <>{errors.name.message}</>
                                    }
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={errors.email !== undefined}>
                                <FormLabel htmlFor='email'>Correo electrónico</FormLabel>
                                <Input
                                    id='email'
                                    type='email'
                                    {...register('email', {
                                        required: 'Campo obligatorio.',
                                        pattern: {
                                            value: /\S+@\S+\.\S+/,
                                            message: 'Introduce un correo electrónico válido.'
                                        }
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.email &&
                                        <>{errors.email.message}</>
                                    }
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={errors.phone !== undefined}>
                                <FormLabel htmlFor='phone'>Teléfono</FormLabel>
                                <Input
                                    id='phone'
                                    type='text'
                                    {...register('phone', {
                                        required: 'Campo obligatorio.',
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.phone &&
                                        <>{errors.phone.message}</>
                                    }
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={errors.content !== undefined}>
                                <FormLabel htmlFor='message'>Mensaje</FormLabel>
                                <Textarea
                                    id='message'
                                    height={40}
                                    resize='none'
                                    placeholder='Describe brevemente lo que estás buscando.'
                                    size='sm'
                                    {...register('content', {
                                        required: 'Campo obligatorio.',
                                    })}
                                />
                                <FormErrorMessage>
                                    {errors.content &&
                                        <>{errors.content.message}</>
                                    }
                                </FormErrorMessage>
                            </FormControl>
                        </Stack>
                        <Stack spacing='6' mt={8}>
                            <Button isLoading={isSubmitting} variant='primary' type='submit'>Enviar</Button>
                        </Stack>
                    </form>
                </Stack>
            </Stack>
        </Container>
    )
}
