import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme'

const config: ThemeConfig = {
    initialColorMode: 'system',
    useSystemColorMode: true
}

export const theme = extendTheme({
    config,
    colors: {
        ...proTheme.colors,
        brand: {
            50: '#d8ffff',
            100: '#acfdff',
            200: '#7dfaff',
            300: '#4df7ff',
            400: '#27f5fe',
            500: '#17dce5',
            600: '#00abb3',
            700: '#007a80',
            800: '#004a4e',
            900: '#001a1d',
        }
    },
    sizes: {
        container: {
            xl: '1440px'
        }
    }
}, proTheme);


