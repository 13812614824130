import { getAnalytics, logEvent } from '@firebase/analytics';
import { Metric, ReportCallback } from 'web-vitals';
import { firebaseApp } from './firebase';

const analytics = getAnalytics(firebaseApp);
const reportWebVitals = (onPerfEntry?: ReportCallback) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};
export const reportToAnalytics = (metric: Metric) => {
  logEvent(analytics, 'Vital Reported', {
    'vital.name': metric.name,
    'vital.value': metric.value,
    'vita.rating': metric.rating,
    'vital.id': metric.id
  });

}
export default reportWebVitals;
