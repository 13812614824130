import { initializeAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';


const firebaseConfig = {
  apiKey: 'AIzaSyBReapyZAGIVLmLdf4U6gCS5EUXIfiYwzU',
  authDomain: 'aero-infinity-dron.firebaseapp.com',
  projectId: 'aero-infinity-dron',
  storageBucket: 'aero-infinity-dron.appspot.com',
  messagingSenderId: '367147985444',
  appId: '1:367147985444:web:d264a4fb37c9b5efa5c6b1',
  measurementId: 'G-2RFP1TKBKT'
};

export const firebaseApp = initializeApp(firebaseConfig);
initializeAnalytics(firebaseApp);

if (process.env.APP_CHECK_DEBUG_TOKEN) {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.APP_CHECK_DEBUG_TOKEN;
}
export const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LcAxjojAAAAAO1gZjlmT9puFO4O107UVNlEJLkD'),
  isTokenAutoRefreshEnabled: true
});