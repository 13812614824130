import {
  Box,
  Button,
  ButtonGroup,
  Image,
  Container,
  Divider,
  HStack,
  IconButton,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react'
import { useNavbar } from './useNavbar'
import { FiMenu } from 'react-icons/fi'
import Logo from '../assets/logo.svg';
import LogoMono from '../assets/logo-mono.svg';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from '../scrollWithOffset';
import { MobileMenu } from './mobileMenu';

export const Navbar = () => {
  const { rootProps } = useNavbar()
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const disclosure = useDisclosure();

  return (
    <Box
      as='nav'
      role='navigation'
      position='sticky'
      top='0'
      zIndex='docked'
      {...rootProps}
    >
      <MobileMenu disclosure={disclosure} />
      <Box as='nav' bg='bg-surface' py={{ base: '4', lg: '5' }}>
        <Container maxW='container.xl'>
          <HStack spacing='10' justify='space-between'>
            <Link to='/' onClick={()=>window.scrollTo({top:0,behavior:'smooth'})}>
              <Image alt='Logo de Aero Infinity Dron' width={220} src={useColorModeValue(Logo, LogoMono)} />
            </Link>
            {isDesktop ? (
              <HStack spacing='10'>
                <ButtonGroup variant='link' spacing='8'>
                  <Button as={HashLink} to='/#portfolio' scroll={scrollWithOffset}>Portfolio</Button>
                  <Button as={HashLink} to='/#testimonials' scroll={scrollWithOffset}>Reseñas</Button>
                  <Button as={HashLink} to='/#services' scroll={scrollWithOffset}>Servicios</Button>
                  {/* <Button as={HashLink} to='/#social' scroll={scrollWithOffset}>Social</Button> */}
                  <Button as={Link} to='/blog'>Blog</Button>
                </ButtonGroup>
                <Box>
                  <Button as={Link} to='/contact' variant='primary' py='12' px='6' my='-5' borderRadius='0'>
                    {'CONTACTO'}
                  </Button>
                </Box>
              </HStack>
            ) : (
              <IconButton
                onClick={disclosure.onOpen}
                variant='ghost'
                icon={<FiMenu fontSize='1.25rem' />}
                aria-label='Open Menu'
              />
            )}
          </HStack>
        </Container>
      </Box>
      <Divider />
    </Box>
  )
}



