import { Avatar, Box, Center, Flex, Heading, Icon, Stack, Text, VStack } from '@chakra-ui/react'
import { ImQuotesLeft, ImQuotesRight } from 'react-icons/im'
import Edu from '../../assets/edu.webp';
import Jordi from '../../assets/jordi.webp';
import { ContactForm } from './contactForm';
import { Helmet } from 'react-helmet-async';


export const Contact = () => (
    <>
        <Helmet>
            <title>Aero Infinity Dron - Ponte en contacto con nosotros</title>
            <link rel='canonical' href='https://www.aeroinfinitydron.com/contact' />
        </Helmet>
        <Box py={{ base: '12', md: '24' }} maxW='7xl' mx='auto'>
            <Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing='12'>
                <Flex flex='1'>
                    <ContactForm />
                </Flex>
                <Center flex='1' px={{ lg: '8' }} display={{ base: 'flex' }}>
                    <VStack spacing='8'>
                        <Stack direction='row' spacing='1'>
                            <Icon as={ImQuotesLeft} boxSize='8' mt='-4' />
                            <Heading size='sm' fontWeight='medium' textAlign='center'>
                                Si eres capaz de imaginarlo somos capaces de hacerlo realidad
                            </Heading>
                            <Icon as={ImQuotesRight} boxSize='8' alignSelf='end' />
                        </Stack>
                        <Stack spacing={12} direction={{ base: 'column', lg: 'row' }}>
                            <VStack spacing='4'>
                                <Avatar
                                    size='xl'
                                    src={Edu}
                                    name='Eduardo Ramón Montoro'
                                />
                                <Stack textAlign='center' spacing='1'>
                                    <Text fontSize='md' fontWeight='medium' color='muted'>
                                        Eduardo Ramón
                                    </Text>
                                    <Text as='a' href='tel:+34679493529' fontWeight='medium' fontSize='sm' color='muted'>
                                        +34 679 493 529
                                    </Text>
                                    <Text as='a' href='mailto:eramon@aeroinfinitydron.com' fontWeight='medium' fontSize='sm' color='muted'>
                                        eramon@aeroinfinitydron.com
                                    </Text>
                                </Stack>
                            </VStack>
                            <VStack spacing='4'>
                                <Avatar
                                    size='xl'
                                    src={Jordi}
                                    name='Jordi Collado'
                                />
                                <Stack textAlign='center' spacing='1'>
                                    <Text fontSize='md' fontWeight='medium' color='muted'>
                                        Jordi Collado
                                    </Text>
                                    <Text as='a' href='tel:+34619904527' fontWeight='medium' fontSize='sm' color='muted'>
                                        +34 619 904 527
                                    </Text>
                                    <Text as='a' href='mailto:jcollado@aeroinfinitydron.com' fontWeight='medium' fontSize='sm' color='muted'>
                                        jcollado@aeroinfinitydron.com
                                    </Text>
                                </Stack>
                            </VStack>
                        </Stack>
                    </VStack>
                </Center>
            </Stack>
        </Box>
    </>
)