import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useColorModeValue,
    Icon,
    Text,
    Link,
    Stack,
    VStack,
    Center,
    Image,
    UseDisclosureReturn,
} from '@chakra-ui/react'
import Logo from '../assets/logo.svg';
import LogoMono from '../assets/logo-mono.svg';
import {
    BsImages,
    BsFillCameraReelsFill,
    BsTelephoneOutboundFill,
    BsFillChatSquareQuoteFill,
    BsHeartFill,
    BsNewspaper
} from 'react-icons/bs'
import { HashLink } from 'react-router-hash-link';
import { Link as RouterLink } from 'react-router-dom';
import { scrollWithOffset } from '../scrollWithOffset';


const items = [
    {
        title: 'Portfolio',
        description: '¡Echa un vistazo a nuestra caja negra!',
        to: '/#portfolio',
        icon: BsImages,
    },
    {
        title: 'Reseñas',
        description: '¿Qué opinan nuestros clientes de nuestro trabajo?',
        to: '/#testimonials',
        icon: BsFillChatSquareQuoteFill,
    },
    {
        title: 'Servicios',
        description: '¿Qué podemos hacer?',
        to: '/#services',
        icon: BsFillCameraReelsFill,
    },
    // {
    //     title: 'Social',
    //     description: 'Síguenos en redes',
    //     to: '/#social',
    //     icon: BsHeartFill,
    // },
    {
        title: 'Blog',
        description: 'Te informaremos de todo lo último del mundo de los drones',
        to: '/blog',
        icon: BsNewspaper,
    },
    {
        title: 'Contacto',
        description: '¿Te decides a trabajar con nosotros? ¡Ponte en contacto!',
        to: '/contact',
        icon: BsTelephoneOutboundFill,
    },
]
export const MobileMenu = ({ disclosure }: { disclosure: UseDisclosureReturn }) => {
    const { onClose, isOpen } = disclosure;
    return (
        <Modal onClose={onClose} size='full' isOpen={isOpen}>
            <ModalContent>
                <ModalHeader />
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={6}>
                        <Center>
                            <RouterLink to='/' onClick={() => {
                                disclosure.onClose();
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}>
                                <Image alt='Logo de Aero Infinity Dron' width={220} src={useColorModeValue(Logo, LogoMono)} />
                            </RouterLink>
                        </Center>
                        <Stack>
                            {items.map((item, id) => (
                                <Link as={HashLink} onClick={disclosure.onClose} variant='menu' to={item.to} key={id} scroll={scrollWithOffset}>
                                    <Stack spacing='4' direction='row' p='3'>
                                        <Icon as={item.icon} boxSize='6' color='accent' />
                                        <Stack spacing='1'>
                                            <Text fontWeight='medium'>{item.title}</Text>
                                            <Text fontSize='sm' color='muted'>
                                                {item.description}
                                            </Text>
                                        </Stack>
                                    </Stack>
                                </Link>
                            ))}
                        </Stack>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}