import {
  Box,
  Flex,
  HStack,
  Img,
  Stack,
  StackProps,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'

interface TestimonialProps extends StackProps {
  name: string;
  content: string;
  company: string;
  image: string;
}

export const Testimonial = (props: TestimonialProps) => {
  const { image, company, name, content, ...rest } = props
  return (
    <Stack
      spacing={6}
      h='100%'
      rounded='2xl'
      shadow='sm'
      py={{ base: '6', md: '12' }}
      px={{ base: '6', md: '14' }}
      bg={mode('white', 'gray.900')}
      color={mode('gray.800', 'gray.300')}
      {...rest}
    >
      <Box fontSize={{ base: 'md', md: 'lg' }} flex='1'>
        {content}
      </Box>
      <HStack spacing={{ base: 3, md: 5 }}>
        <Img alt='Foto de perfil del cliente autor de la reseña' objectFit='cover' rounded='full' boxSize={14} src={image} />
        <Flex direction='column'>
          <Text fontWeight='bold'>{name}</Text>
          <Text fontSize='sm' fontWeight='medium' opacity={0.7}>
            {company}
          </Text>
        </Flex>
      </HStack>
    </Stack>
  )
}