import { Box, Heading, SkeletonCircle, SkeletonText, Stack, Text } from '@chakra-ui/react'
import { collection, getFirestore } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Helmet } from 'react-helmet-async';
import { firebaseApp } from '../../firebase';
import { PostSummary } from './postSummary';

export const Blog = () => {
    const [posts, loading] = useCollection(collection(getFirestore(firebaseApp), 'posts'));
    const getFilteredAndSortedPosts = () => {
        return posts?.docs
            .filter(p => p.data().visible)
            .sort((a, b) => {
                const aPublished = DateTime.fromISO(a.data().published);
                const bPublished = DateTime.fromISO(b.data().published);
                if (aPublished > bPublished) {
                    return -1
                } else if (bPublished > aPublished) {
                    return 1
                }
                return 0
            })
    }
    return (
        <>
            <Helmet>
                <link rel='canonical' href={'https://www.aeroinfinitydron.com/blog'} />
            </Helmet>
            <Stack spacing='10' shouldWrapChildren px={4}>
                <Box pt='8'>
                    <Stack spacing={{ base: '8', md: '10' }} align='center'>
                        <Stack spacing={{ base: '4', md: '6' }} textAlign='center'>
                            <Stack spacing='4'>
                                <Text fontWeight='semibold' color='accent' fontSize={{ base: 'sm', md: 'md' }}>
                                    {'Nuestro Blog'}
                                </Text>
                                <Heading size={{ base: 'md', md: 'lg' }}>Últimas novedades</Heading>
                            </Stack>
                            <Text fontSize={{ base: 'lg', md: 'xl' }} color='muted'>
                                {'Te mantendremos informado de todo lo relevante del mundo de los drones'}
                            </Text>
                        </Stack>
                    </Stack>
                </Box>
                <Box maxW='2xl' mx='auto'>
                    <Stack spacing='8'>
                        {loading &&
                            <>
                                <Box padding='6'>
                                    <SkeletonCircle size='10' />
                                    <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                                </Box>
                                <Box padding='6'>
                                    <SkeletonCircle size='10' />
                                    <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                                </Box>
                                <Box padding='6'>
                                    <SkeletonCircle size='10' />
                                    <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
                                </Box>
                            </>
                        }
                        {getFilteredAndSortedPosts()?.map((post) => (
                            <PostSummary key={post.id} post={{ ...post.data(), id: post.id }} />
                        ))}
                    </Stack>
                </Box>
            </Stack>
        </>
    )
}