import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    UseDisclosureReturn,
    AspectRatio
} from "@chakra-ui/react"

export const VideoModal = ({ disclosure, embedId }: { disclosure: UseDisclosureReturn, embedId: string }) => {
    const { onClose, isOpen } = disclosure;
    const url = `https://www.youtube.com/embed/${embedId}?&autoplay=1`;
    return (
        <Modal onClose={onClose} size='6xl' isOpen={isOpen} isCentered >
            <ModalOverlay bg='blackAlpha.700' backdropFilter='auto' backdropBlur='2px' />
            <ModalContent>
                <ModalHeader />
                <ModalCloseButton />
                <ModalBody mt={1} mb={2}>
                    <AspectRatio ratio={16 / 9}>
                        <iframe
                            src={url}
                            frameBorder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                            title='Embedded youtube'
                        />
                    </AspectRatio>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}