import { Brands } from "./brands/brands"
import { Hero } from "./hero/hero"
import { Portfolio } from "./portfolio/portfolio"
import { Services } from "./services/services"
import { Testimonials } from "./testimonials/testimonials"

export const Home = () => {

    return (
        <>
            <Hero />
            <Brands />
            <Portfolio />
            <Testimonials />
            <Services />
            {/* <Social /> */}
        </>
    )
}