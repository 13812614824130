import {
    AspectRatio,
    Box,
    Circle,
    Image,
    Skeleton,
    useBreakpointValue,
    useDisclosure,
    VisuallyHidden,
} from '@chakra-ui/react'
import { FaPlay } from 'react-icons/fa'
import { VideoModal } from '../../../components/videoModal';

interface Props {
    embedId: string;
    thumbnail: string;
}

export const Entry = (props: Props) => {
    const videoModalDisclosure = useDisclosure();
    return (
        <>
            <VideoModal disclosure={videoModalDisclosure} embedId={props.embedId} />
            <Box position="relative" role='group'>
                <AspectRatio maxWidth='100%' width={'300px'} ratio={16 / 9}>
                    <Image
                        alt='Portada del vídeo'
                        src={props.thumbnail}
                        objectFit='cover'
                        draggable='false'
                        fallback={<Skeleton />}
                        borderRadius={useBreakpointValue({ base: 'md', md: 'xl' })}
                    />

                </AspectRatio>
                <Circle
                    size='14'
                    as='button'
                    bg='whiteAlpha.800'
                    shadow='lg'
                    color='brand.600'
                    position='absolute'
                    top='50%'
                    left='50%'
                    transform='translate3d(-50%, -50%, 0)'
                    fontSize='xl'
                    transition='all 0.2s'
                    onClick={videoModalDisclosure.onOpen}
                    _groupHover={{
                        transform: 'translate3d(-50%, -50%, 0) scale(1.1)',
                    }}
                >
                    <VisuallyHidden>Play demo video</VisuallyHidden>
                    <FaPlay />
                </Circle>
            </Box>
        </>
    )
}