import {
    Box,
    Text,
    Slider,
    SliderMark,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb
} from '@chakra-ui/react'
import React from 'react';
import { FaEuroSign } from 'react-icons/fa';

interface Props {
    initialAmount: number;
    onAmountUpdate: (amount: number) => void;
}

export const AmountSlider = (props: Props) => {
    const [amount, setAmount] = React.useState(props.initialAmount);
    return (
        <>
            <Text align={'center'} fontWeight='bold' fontSize={'40px'} color='accent'>
                {`${amount},00 €`}
            </Text>
            <Box mb={16} width='100%' display='flex' justifyContent={'center'}>
                <Box w={{ base: '350px', md: '600px' }}>
                    <Slider
                        size='lg'
                        defaultValue={props.initialAmount}
                        step={1}
                        min={5}
                        max={50}
                        colorScheme='brand'
                        onChange={setAmount}
                        onChangeEnd={props.onAmountUpdate}
                    >
                        <SliderMark value={5} mt='6' fontSize='2xl'>
                            🙂
                        </SliderMark>
                        <SliderMark value={15} mt='6' fontSize='2xl'>
                            😳
                        </SliderMark>
                        <SliderMark value={25} mt='6' fontSize='2xl'>
                            😲
                        </SliderMark>
                        <SliderMark value={35} mt='6' fontSize='2xl'>
                            🤤
                        </SliderMark>
                        <SliderMark value={49} mt='6' fontSize='2xl'>
                            🤑
                        </SliderMark>
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb boxSize={10}>
                            <Box color='brand.600' as={FaEuroSign} />
                        </SliderThumb>
                    </Slider>
                </Box>
            </Box>
        </>
    )
}
