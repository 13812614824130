import { Box, Grid, Heading, Link, Text } from '@chakra-ui/react'
import { collection, getFirestore } from 'firebase/firestore';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebaseApp } from '../../../firebase';
import { Rating } from './rating'
import { Testimonial } from './testimonial'


export const Testimonials = () => {
    const [value, loading] = useCollection(collection(getFirestore(firebaseApp), 'testimonials'));
    const [visibleTestimonialId, setVisibleTestimonialId] = React.useState<string | undefined>(undefined);
    const testimonials: any[] | undefined = value?.docs.map(t => ({ id: t.id, ...t.data() }))
    const getNextTestimonial = () => {
        if (!testimonials) return;
        const index = testimonials.findIndex(t => t.id === visibleTestimonialId);
        if (index == -1) {
            return testimonials[Math.floor(Math.random() * testimonials.length)];
        }
        if (index >= testimonials?.length - 1) {
            return testimonials[0];
        } else {
            return testimonials[index + 1];
        }
    }

    React.useEffect(() => {
        if (!loading) {
            const timeout = setTimeout(() => {
                setVisibleTestimonialId(getNextTestimonial()?.id)
            }, 8000);
            return () => clearTimeout(timeout);
        }
    }, [visibleTestimonialId])
    React.useEffect(() => {
        setVisibleTestimonialId(getNextTestimonial()?.id)
    }, [loading])
    return (
        <Box as='section' id='testimonials' bg='brand.700' color='white' py='8' my='8'>
            <Box maxW={{ base: 'xl', md: '7xl' }} mx='auto' px={{ base: '6', md: '8' }} py='10' minH={450}>
                <Grid templateColumns={{ base: '1fr', lg: '460px 1fr' }} gap={{ base: '10', lg: '8' }}>
                    <Box>
                        <Text
                            textTransform='uppercase'
                            fontWeight='semibold'
                            color='yellow.400'
                            letterSpacing='wide'
                        >
                            {'Más de 50 empresas ya confían en nosotros'}
                        </Text>
                        <Heading as='h3' size={{ base: 'md', lg: 'lg' }} mt='7' lineHeight='shorter'>
                            {'¿Qué opinan de nuestros trabajos?'}
                        </Heading>
                        <Rating mt='10' value={5} />
                        <Text mt='4'>
                            <b>5/5</b> en <Link>Google</Link>
                        </Text>
                    </Box>
                    <Box maxW={{ base: 'unset', lg: '37.5rem' }} mx='auto'>
                        <AnimatePresence initial={true} mode='wait'>
                            {testimonials?.map(t => {
                                if (t.id === visibleTestimonialId) {
                                    return (
                                        <motion.div
                                            key={t.id}
                                            initial={{ opacity: 0, y: -100 }}
                                            transition={{ duration: 0.6 }}
                                            animate={{
                                                opacity: 1, y: 0
                                            }}
                                            exit={{ opacity: 0, y: 100 }}>
                                            <Testimonial
                                                name={t.name}
                                                company={t.title}
                                                image={t.image}
                                                content={t.content}
                                            />
                                        </motion.div>
                                    )
                                }
                            })}
                        </AnimatePresence>
                    </Box>
                </Grid>
            </Box>
        </Box>
    )
}