import { Box, Button, Heading, Link, Stack, Text } from '@chakra-ui/react';
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from "../scrollWithOffset";

type Props = {
    title: string;
    description: string;
    link: string;
    buttonLabel: string;
}

export const CTA = (props: Props) => {
    return (
        <Box
            bg={['brand.800']}
            color='on-accent'
            borderRadius='xl'
            px={{ base: '6', lg: '16' }}
            py={{ base: '10', lg: '16' }}
        >
            <Stack spacing='8' direction={{ base: 'column', lg: 'row' }} justify='space-between' alignItems='center'>
                <Stack spacing='4' maxW='2xl'>
                    <Heading size='sm'>{props.title}</Heading>
                    <Text color='on-accent-muted' fontSize={['lg', 'xl']}>
                        {props.description}
                    </Text>
                </Stack>
                <Stack spacing='3' direction={{ base: 'column', sm: 'row' }} justify={{ base: 'start' }}>
                    <Button as={HashLink} scroll={scrollWithOffset} to={props.link} variant='secondary-on-accent' size='xl'>
                        {props.buttonLabel}
                    </Button>
                </Stack>
            </Stack>
        </Box>
    )
}