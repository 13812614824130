import {
    Image,
    AspectRatio,
    Stack,
    Skeleton,
    Text,
    Heading,
    HStack,
    Avatar,
    Box,
    useColorModeValue
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { HiArrowRight } from 'react-icons/hi'
import { Helmet } from 'react-helmet-async';

export const PostSummary = (props: any) => {
    const { post } = props;
    return (
        <>
            <Helmet>
                <title>{'Aero Infinity Dron - Blog'}</title>
                <link rel='canonical' href='https://www.aeroinfinitydron.com/blog' />
            </Helmet>
            <Stack spacing='6'>
                <Link to={`/blog/${post.id}`}>
                    <AspectRatio ratio={16 / 9}>
                        <Image
                            src={post.image}
                            objectPosition='top'
                            objectFit='cover'
                            fallback={<Skeleton />}
                            alt={post.title}
                            borderRadius='xl'
                        />
                    </AspectRatio>
                </Link>
                <Stack spacing='1'>
                    <Text fontSize='sm' fontWeight='semibold' color='accent'>
                        {DateTime.fromISO(post.published).setLocale('es-ES').toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}
                    </Text>
                    <Heading
                        as={Link}
                        to={`/blog/${post.id}`}
                        size='xs'
                        fontWeight='semibold'
                        fontSize={{ base: 'xl', lg: '2xl' }}
                        lineHeight={{ base: '1.5', lg: '2rem' }}
                    >
                        {post.title}
                    </Heading>
                </Stack>
                <Text>{post.summary}</Text>
                <HStack
                    className='group'
                    as={Link}
                    to={`/blog/${post.id}`}
                    fontSize='sm'
                    flexGrow={0}
                    display='inline-flex'
                >
                    <Box fontWeight='medium'>Seguir leyendo</Box>
                    <Box
                        aria-hidden
                        transition='0.2s all'
                        _groupHover={{ transform: 'translateX(2px)' }}
                        as={HiArrowRight}
                        display='inline-block'
                    />
                </HStack>
                <HStack spacing='3'>
                    <Avatar size='md' name={post.authorName} src={post.authorAvatar} />
                    <Box lineHeight='1.25rem'>
                        <Text fontSize='sm' color='default'>
                            {post.authorName}
                        </Text>
                        <Text fontSize='sm'>{DateTime.fromISO(post.published).setLocale('es-ES').toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}</Text>
                    </Box>
                </HStack>
            </Stack>
        </>
    )
}