import { Flex } from '@chakra-ui/react';
import { Main } from './main';
import { Navbar } from './navbar';
import { Footer } from './footer';

export default function Layout() {
    return (
        <Flex direction='column' flex='1'>
            <Navbar />
            <Main />
            <Footer />
        </Flex>
    );
}

