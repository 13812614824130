import { Image, Box, SimpleGrid, Skeleton, Text, useColorModeValue, AspectRatio } from '@chakra-ui/react';

import colaDark from '../../../assets/brands/cola-gray-400.svg'
import colaLight from '../../../assets/brands/cola-gray-600.svg'
import cuatroDark from '../../../assets/brands/cuatro-gray-400.svg'
import cuatroLight from '../../../assets/brands/cuatro-gray-600.svg'
import dipcasDark from '../../../assets/brands/dipcas-gray-400.svg'
import dipcasLight from '../../../assets/brands/dipcas-gray-600.svg'
import mediasetDark from '../../../assets/brands/mediaset-gray-400.svg'
import mediasetLight from '../../../assets/brands/mediaset-gray-600.svg'
import neolithDark from '../../../assets/brands/neolith-gray-400.svg'
import neolithLight from '../../../assets/brands/neolith-gray-600.svg'
import redbullDark from '../../../assets/brands/redbull-gray-400.svg'
import redbullLight from '../../../assets/brands/redbull-gray-600.svg'


export const Brands = () => {
    return (
        <Box as='section' pb='8' pt={12}>
            <Box maxW={{ base: 'xl', md: '7xl' }} mx='auto' px={{ base: '6', md: '8' }}>
                <Text
                    textAlign='center'
                    fontSize='md'
                    textTransform='uppercase'
                    fontWeight='semibold'
                    color='accent'
                    letterSpacing='wide'>
                    {'Empresas que confían en nosotros'}
                </Text>
                <SimpleGrid
                    mt='8'
                    columns={{ base: 2, md: 3, lg: 6 }}
                    color='gray.500'
                    alignItems='center'
                    justifyItems='center'
                    spacing={{ base: '12', md: '16' }}
                    fontSize='2xl'
                >
                    <Image
                        alt='Logo de CocaCola, cliente de Aero Infinity Dron'
                        src={useColorModeValue(colaLight, colaDark)}
                        draggable='false'
                    />
                    <Image
                        alt='Logo de Cuatro, cliente de Aero Infinity Dron'
                        src={useColorModeValue(cuatroLight, cuatroDark)}
                        draggable='false'
                    />
                    <Image
                        alt='Logo de la Diputación de Castellón, cliente de Aero Infinity Dron'
                        src={useColorModeValue(dipcasLight, dipcasDark)}
                        draggable='false'
                    />
                    <Image
                        alt='Logo de Mediaset, cliente de Aero Infinity Dron'
                        src={useColorModeValue(mediasetLight, mediasetDark)}
                        draggable='false'
                    />
                    <Image
                        alt='Logo de Neolith, cliente de Aero Infinity Dron'
                        src={useColorModeValue(neolithLight, neolithDark)}
                        draggable='false'
                    />
                    <Image
                        alt='Logo de RedBull, cliente de Aero Infinity Dron'
                        src={useColorModeValue(redbullLight, redbullDark)}
                        draggable='false'
                    />
                </SimpleGrid>
            </Box>
        </Box>
    )
}