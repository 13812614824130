import {
    Avatar,
    Box,
    Heading,
    HStack,
    SkeletonText,
    Stack,
    Text
} from '@chakra-ui/react';
import { doc, getFirestore } from 'firebase/firestore';
import { useDocument } from 'react-firebase-hooks/firestore';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import { firebaseApp } from '../../firebase';
import remarkGfm from 'remark-gfm';
import { DateTime } from 'luxon';
import { Helmet } from 'react-helmet-async';
import { MarkdownComponents } from './markdownComponents';
import { CTA } from '../../components/cta';


export const Post = () => {
    let { id } = useParams();
    const [post, loadingPost] = useDocument(doc(getFirestore(firebaseApp), 'posts', id ?? ''));

    if (loadingPost || !post?.exists()) {
        return (
            <Box py={8}>
                <SkeletonText noOfLines={10} spacing={6} skeletonHeight={6} />
            </Box>
        )
    }

    return (
        <>
            <Helmet>
                <title>{post?.data()?.title}</title>
                <meta name='description' content={post?.data()?.summary} />
                <link rel='canonical' href={'https://www.aeroinfinitydron.com/blog/' + post.id} />
            </Helmet>
            <Box>
                <Box py={8} px={4}>
                    <Heading fontSize={{ base: '26px', lg: '36px' }} lineHeight={{ base: '30px', lg: '40px' }} as='h1' mb={4} >{post?.data()?.title}</Heading>
                    <Box mb={4} display='flex' justifyContent={'flex-start'}>
                        <HStack spacing={4}>
                            <Avatar size={['lg', 'xl']} name={post?.data()?.authorName} src={post?.data()?.authorAvatar} />
                            <Stack>
                                <Text fontWeight={'bold'}>
                                    {post?.data()?.authorName}
                                </Text>
                                <em>Actualizado el {DateTime.fromISO(post?.data()?.published).setLocale('es-ES').toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}</em>
                            </Stack>
                        </HStack>
                    </Box>
                    <ReactMarkdown
                        components={MarkdownComponents}
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[remarkGfm]}
                        children={post?.data()?.content} />
                </Box>
                <CTA
                    title={'¿Listo para iniciar tu proyecto?'}
                    description={'Ponte en contacto con nosotros. Te aseroraremos y prepararemos la oferta que más se adapte a tus necesidades.'}
                    link={'/contact'}
                    buttonLabel={'CONTACT'} />

            </Box>
        </>
    )

}
