import {
    Alert,
    AlertDescription,
    AlertIcon,
    Center,
    Code,
    Heading,
    Image,
    Stack,
    Table,
    TableContainer,
    Text,
    Link as ChakraLink,
    AspectRatio
} from '@chakra-ui/react';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';

export const MarkdownComponents = ChakraUIRenderer({
    h1: props => (
        <Heading mt={8} mb={4} fontSize={{ base: '22px', lg: '32px' }} lineHeight={{ base: '26px', lg: '36px' }} as='h2'>{props.children}</Heading>
    ),
    h2: props => (
        <Heading mt={8} mb={4} fontSize={{ base: '18px', lg: '28px' }} lineHeight={{ base: '22px', lg: '32px' }} as='h3'>{props.children}</Heading>
    ),
    h3: props => (
        <Heading mt={8} mb={4} fontSize={{ base: '16px', lg: '26px' }} lineHeight={{ base: '20px', lg: '28px' }} as='h4'>{props.children}</Heading>
    ),
    h4: props => (
        <Heading mt={8} mb={4} fontSize={{ base: '16px', lg: '26px' }} lineHeight={{ base: '20px', lg: '28px' }} fontWeight='light' as='h5'>{props.children}</Heading>
    ),
    h5: props => (
        <Heading mt={8} mb={4} fontSize={{ base: '14px', lg: '24px' }} lineHeight={{ base: '18px', lg: '28px' }} fontWeight='light' as='h6'>{props.children}</Heading>
    ),
    a: props => (
        <ChakraLink fontWeight={'bold'} color={'brand.600'} href={props.href ?? ''} isExternal>{props.children}</ChakraLink>
    ),
    img: props => (
        <Center my={4}>
            <Stack>
                <Image style={{ maxWidth: '100%', width: '800px' }} src={props.src} alt={props.alt}></Image>
                <Text as='em'>{props.alt}</Text>
            </Stack>
        </Center>
    ),
    blockquote: props => (
        <Alert my={8} sx={{ p: { marginBottom: 0 } }} status='info'>
            <AlertIcon />
            <AlertDescription>
                {props.children}
            </AlertDescription>
        </Alert>
    ),
    code: props => {
        const { inline, children, className } = props;

        if (inline) {
            return <Code p={2} children={children} />;
        }

        return (
            <Code
                my={8}
                className={className}
                whiteSpace='break-spaces'
                display='block'
                w='full'
                p={2}
                children={children}
            />
        );
    },
    table: props => (
        <TableContainer>
            <Table>
                {props.children}
            </Table>
        </TableContainer>
    ),
    iframe: props => {
        return (
            <AspectRatio ratio={16 / 9} maxW='800px' mx={'auto'} my={6}>
                <iframe {...props} />
            </AspectRatio>
        )
    }
})