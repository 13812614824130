import {
  Box,
  Button,
  Heading,
  Icon,
  Img,
  Square,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FiArrowRight } from 'react-icons/fi'
import { features } from './data'
import ServicesImg from '../../../assets/services.webp';
import { VideoModal } from '../../../components/videoModal';
import React from 'react';

export const Services = () => {
  const [embedId, setEmbedId] = React.useState('');
  const disclosue = useDisclosure();
  return (
    <Box as='section' id='services' py='8'>
      <VideoModal embedId={embedId} disclosure={disclosue} />
      <Stack spacing={{ base: '12', md: '16' }}>
        <Stack spacing={{ base: '4', md: '5' }} maxW='3xl'>
          <Stack spacing='3'>
            <Text
              fontSize='md'
              textTransform='uppercase'
              fontWeight='semibold'
              color='accent'
              letterSpacing='wide'>
              {'Servicios'}
            </Text>
            <Heading size={{ base: 'sm', md: 'md' }}>
              {'¿Qué podemos hacer?'}
            </Heading>
          </Stack>
          <Text color='muted' fontSize={{ base: 'lg', md: 'xl' }}>
            {'Nos encargamos desde la creación del concepto creativo hasta la toma de imagenes y la edición del proyecto.'}
          </Text>
        </Stack>
        <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '12', lg: '16' }}>
          <Stack spacing={{ base: '10', md: '12' }} maxW='xl' justify='center' width='full'>
            {features.map((feature) => (
              <Stack key={feature.name} spacing='4' direction='row'>
                <Square
                  size={{ base: '10', md: '12' }}
                  bg='accent'
                  color='inverted'
                  borderRadius='lg'
                >
                  <Icon as={feature.icon} boxSize={{ base: '5', md: '6' }} />
                </Square>
                <Stack spacing={{ base: '4', md: '5' }} pt={{ base: '1.5', md: '2.5' }}>
                  <Stack spacing={{ base: '1', md: '2' }}>
                    <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight='medium'>
                      {feature.name}
                    </Text>
                    <Text color='muted'>{feature.description}</Text>
                  </Stack>
                  <Button
                    variant='link'
                    colorScheme='blue'
                    rightIcon={<FiArrowRight fontSize='1.25rem' />}
                    alignSelf='start'
                    onClick={() => {
                      setEmbedId(feature.embedId);
                      disclosue.onOpen();
                    }}
                  >
                    Ver un ejemplo
                  </Button>
                </Stack>
              </Stack>
            ))}
          </Stack>
          <Box
            w={{ base: 'full', lg: '50%' }}
            height={{ sm: '96', md: 'inherit' }}
            sx={{
              clipPath: {
                base: 'polygon(0 0, 75% 0, 100% 100%, 25% 100%)'
              },
            }}
          >
            <Img
              alt='Equipo de Aero Infinity Dron en una instalación industrial volando con drones'
              height='100%'
              width='100%'
              objectFit='cover'
              src={ServicesImg}
            />
          </Box>
        </Stack>
      </Stack>
    </Box >
  )
}