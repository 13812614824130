import { BsStars } from 'react-icons/bs'
import { MdHome, MdShopTwo } from 'react-icons/md'
import { IoRocketSharp } from 'react-icons/io5'

export const features = [
  {
    name: 'Tour indoor',
    description: 'Te ayudamos a acelerar la venta de un inmueble o dar a conocer tu espacio desde un ángulo impactante.',
    embedId: 'OCFgSpIG4-Y',
    icon: MdHome,
  },
  {
    name: 'Trailer publicitario',
    description:
      'Diferenciate de tu competencia con un trailer desde una perspectiva única.',
    embedId: 'Nm82-PYl_Mo',
    icon: MdShopTwo,
  },
  {
    name: 'Acción',
    description:
      'Velocidad y agilidad es lo que define un dron. Te retamos a escapar de nuestro ángulo de visión.',
    embedId: 'XQgtSWZ_qts',
    icon: IoRocketSharp,
  },
  {
    name: 'Tu imaginación es el límite',
    description:
      'Podemos hacer realidad cualquier idea que te pueda surgir. Nuestros drones te dan alas.',
    embedId: 'jkvKV4zT_Us',
    icon: BsStars,
  },
]