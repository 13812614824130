import { Container, Flex, FlexProps } from '@chakra-ui/react'
import { Outlet } from 'react-router'

export const Main = (props: FlexProps) => {
  return (
    <Flex as='main' role='main' direction='column' flex='1'  {...props}>
      <Container maxW='container.xl' pb={16}>
        <Outlet />
      </Container>
    </Flex>
  )
}