import {
  Badge,
  Box,
  Button,
  Circle,
  Heading,
  HStack,
  Img,
  Stack,
  Text,
  useColorModeValue as mode,
  useDisclosure,
  VisuallyHidden,
} from '@chakra-ui/react'
import { FaPlay } from 'react-icons/fa'
import { HiArrowRight } from 'react-icons/hi'
import { VideoModal } from '../../../components/videoModal'
import HeroImg from '../../../assets/hero.webp';
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from '../../../scrollWithOffset';
import { Link } from 'react-router-dom';

const markedText = (text: string) => (
  <Box as='mark' color={mode('brand.500', 'brand.300')} bg='transparent'>
    {text}
  </Box>
)

export const Hero = () => {
  const videoModalDisclosure = useDisclosure();
  return (
    <>
      <VideoModal disclosure={videoModalDisclosure} embedId='aNA0u15L9Mw' />
      <Box
        as='section'
        pos='relative'
        px={{ base: '6', lg: '12' }}
      >
        <Box maxW='7xl' mx='auto'>
          <Box
            maxW={{ lg: 'md', xl: 'xl' }}
            pt={{ base: '16', lg: '24' }}
            pb={{ base: '16', lg: '24' }}
          >
            {/* <HStack
              className='group'
              as={Link}
              to='/deliveries'
              px='2'
              py='1'
              bg={mode('gray.200', 'gray.700')}
              rounded='full'
              fontSize='sm'
              mb='8'
              display='inline-flex'
              minW='14rem'
            >
              <Badge
                px='2'
                variant='solid'
                colorScheme='brand'
                rounded='full'
                textTransform='capitalize'
              >
                {'NUEVO'}
              </Badge>
              <Box fontWeight='medium'>¿Eres cliente y tienes un código de descarga?</Box>
              <Box
                aria-hidden
                transition='0.2s all'
                _groupHover={{ transform: 'translateX(2px)' }}
                as={HiArrowRight}
                display='inline-block'
              />
            </HStack> */}
            <Heading as='h1' size={{ base: 'md', lg: 'xl' }} lineHeight='1' fontWeight='extrabold' letterSpacing='tight'>
              {`Somos `} {markedText('expertos')} en crear imágenes {markedText('espectaculares')} con drones.
            </Heading>
            <Text mt={4} fontSize='xl' fontWeight='medium' color={mode('gray.600', 'gray.400')}>
              {'Desde tours virtuales de interiores de viviendas, hoteles y negocios hasta clips promocionales y eventos deportivos.'}
            </Text>
            <Stack direction={{ base: 'column', sm: 'row' }} spacing='4' mt='8'>
              <Button as={Link} to='/contact' size='lg' colorScheme='brand' height='14' px='8' fontSize='md'>
                {'CONTACTO'}
              </Button>
              <Button
                size='lg'
                as={HashLink}
                scroll={scrollWithOffset}
                to='/#portfolio'
                smooth
                bg='white'
                color='gray.800'
                _hover={{ bg: 'gray.50' }}
                height='14'
                px='8'
                shadow='base'
                fontSize='md'
              >
                {'PORTFOLIO'}
              </Button>
            </Stack>
          </Box>
        </Box>
        <Box
          pos={{ base: 'relative', lg: 'absolute' }}
          insetY={{ lg: '0' }}
          insetEnd={{ lg: '0' }}
          bg='gray.50'
          w={{ base: 'full', lg: '50%' }}
          height={{ base: '96', lg: 'full' }}
          sx={{
            clipPath: {
              lg: 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)',
              base: 'polygon(0 0, 75% 0, 100% 100%, 25% 100%)'
            },
          }}
        >
          <Img
            alt='Piloto de drones volando con dron FPV'
            height='100%'
            width='100%'
            objectFit='cover'
            src={HeroImg}
          />
          <Circle
            size='20'
            as='button'
            bg='white'
            shadow='lg'
            color='brand.600'
            position='absolute'
            top='50%'
            left='50%'
            transform='translate3d(-50%, -50%, 0)'
            fontSize='xl'
            transition='all 0.2s'
            onClick={videoModalDisclosure.onOpen}
            _hover={{
              transform: 'translate3d(-50%, -50%, 0) scale(1.05)',
            }}
          >
            <VisuallyHidden>Play demo video</VisuallyHidden>
            <FaPlay />
          </Circle>
        </Box>
      </Box>
    </>
  )
}
