import {
    Box,
    Heading,
    Stack,
    Tag,
    TagLabel,
    Text,
    useBreakpointValue,
    Skeleton,
} from '@chakra-ui/react'
import React from 'react';
import { Entry } from './entry'
import { Grid } from './grid'
import { getFirestore, collection } from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebaseApp } from '../../../firebase';

export const Portfolio = () => {
    const [value, loading] = useCollection(collection(getFirestore(firebaseApp), 'portfolio'));
    const tagsMap: { [key: string]: boolean } = {};
    value?.docs.forEach(d => {
        d.data().tags?.forEach((t: string) => {
            tagsMap[t] = true;
        })
    })
    const tags = Object.keys(tagsMap);
    const [selectedTag, setSelectedTag] = React.useState('');
    React.useEffect(() => {
        if (tags.length > 0) {
            setSelectedTag(tags[0]);
        }
    }, [value]);

    return (
        <>
            <Stack as='section' id='portfolio' py='8' spacing={{ base: 6 }}>
                <Stack spacing='3' align='center' textAlign='center'>
                    <Text
                        fontSize='md'
                        textTransform='uppercase'
                        fontWeight='semibold'
                        color='accent'
                        letterSpacing='wide'>
                        {'Portfolio'}
                    </Text>
                    <Heading size={{ base: 'sm', md: 'md' }}>{'Nuestra caja negra'}</Heading>
                </Stack>
                <Box display='flex' justifyContent='center' columnGap='4' rowGap='4' flexWrap='wrap'>
                    {tags.map(t => (
                        <Tag
                            key={t}
                            size='lg'
                            variant={t === selectedTag ? 'solid' : 'outline'}
                            colorScheme='brand'
                            sx={{ cursor: 'pointer' }}
                            onClick={() => setSelectedTag(t)}>
                            <TagLabel>{t}</TagLabel>
                        </Tag>
                    ))}
                </Box>
                <Skeleton isLoaded={!loading} minH={40}>
                    <Grid>
                        {value?.docs.filter(e => e.data().tags?.includes(selectedTag)).map(e => (
                            <Entry key={e.id} embedId={e.data().embedId} thumbnail={e.data().thumbnail} />
                        ))}
                    </Grid>
                </Skeleton>
            </Stack>
        </>
    )
}