import { ChakraProvider } from '@chakra-ui/react';
import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Layout from './layout/layout';
import { Contact } from './routes/contact/contact';
import { Deliveries } from './routes/deliveries/deliveries';
import { Home } from './routes/home/home';
import { theme } from './theme';
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from './firebase';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Post } from './routes/blog/post';
import { Blog } from './routes/blog/blog';
import { Event } from './routes/events/event';

const PathObserver = () => {
    const location = useLocation();
    const analytics = getAnalytics(firebaseApp);
    useEffect(() => {
        window.scrollTo({ top: 0 });
        const pagePath = location.pathname + location.search + location.hash;
        logEvent(analytics, 'page_view', {
            page_path: pagePath
        });
    }, [location]);
    return null
}


export const App = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Aero Infinity Dron - Expertos en crear vídeos con Drones</title>
                <link rel='canonical' href='https://www.aeroinfinitydron.com' />
                <meta name='description'
                    content='Expertos en crear vídeos espectaculares con drones. Tours de interiores, vídeos de bodas, vídeos promocionales y grabación de eventos deportivos.' />
            </Helmet>
            <ChakraProvider theme={theme}>
                <BrowserRouter>
                    <PathObserver />
                    <Routes>
                        <Route path='/' element={<Layout />}>
                            <Route index element={<Home />} />
                            <Route path='contact' element={<Contact />} />
                            <Route path='blog' element={<Blog />} />
                            <Route path='blog/:id' element={<Post />} />
                            <Route path='events/:id' element={<Event />} />
                            <Route path='events' element={<Navigate to='/events/benikarts-100-vueltas-260223' replace />} />
                        </Route>
                        <Route path='/deliveries'>
                            <Route index element={<Deliveries />} />
                        </Route>
                        <Route path='*' element={<Navigate to='/' replace />} />
                    </Routes>
                </BrowserRouter>
            </ChakraProvider>
        </HelmetProvider>
    );
}